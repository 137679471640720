// Tabs.js
import React, { useState, useEffect } from 'react';
import DemographicsTab from './ProfileTableComps/DemographicsTab';
import ScheduleTab from './ProfileTableComps/ScheduleTab';
import NotesTab from './ProfileTableComps/NotesTab';
import BillingTab from './ProfileTableComps/BillingTab';
import DocumentsTab from './ProfileTableComps/DocumentsTab';
import STInsurance from './ProfileTableComps/STInsurance/STInsurance';
import OTInsurance from './ProfileTableComps/OTInsurance/OTInsurance';
import PGInsurance from './ProfileTableComps/PGInsurance';
import OfficeNotes from './ProfileTableComps/OfficeNotes';
/* const getInitialTab = () => {
    const storedTab = localStorage.getItem('activeTab')
    return storedTab || 'demographics';
}; */

const ProfileTable = () => {
  //const [activeTab, setActiveTab] = useState(getInitialTab);
  const [activeTab, setActiveTab] = useState('demographics');
  const [serviceOption, setServiceOption] = useState(false);
  const [showDropdown, setShowDropdown] = useState('');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setServiceOption('');
    setShowDropdown(false);
    //localStorage.setItem('activeTab', tab);
  };

  const handleInsuranceOptionClick = (option) => {
    setServiceOption(option);
    setShowDropdown(false);
    setActiveTab('');
  };

  /* useEffect(() => {
    const storedTab = getInitialTab();
    setActiveTab(storedTab);
  }, []); */

  const renderTabContent = () => {
    if (serviceOption) {
      // Render the insurance option content
      switch (serviceOption) {
        case 'stinsurance':
          return <STInsurance />;
        case 'otinsurance':
          return <OTInsurance />;
        case 'pginsurance':
          return <PGInsurance />;
        default:
          return null;
      }
    } else {
      // Render the main tab content
      switch (activeTab) {
        case 'demographics':
          return <DemographicsTab />;
        case 'schedule':
          return <ScheduleTab />;
        case 'notes':
          return <NotesTab />;
        case 'billing':
          return <BillingTab />;
        case 'documents':
          return <DocumentsTab />;
        case 'officenotes':
          return <OfficeNotes />;
        default:
          return null;
      }
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md h-full">
      <div className="flex justify-center border-b border-gray-200">
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'demographics'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('demographics')}
        >
          Demographics
        </button>
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'schedule'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('schedule')}
        >
          Schedule
        </button>
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'notes'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('notes')}
        >
          Notes
        </button>
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'billing'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('billing')}
        >
          Billing
        </button>
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'documents'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('documents')}
        >
          Documents
        </button>
        <div className="">
        <button
            className={`px-4 py-2 font-medium text-sm ${
            serviceOption ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setShowDropdown(!showDropdown)}
        >
            Payment Information
          </button>
          {showDropdown && (
            <div className="absolute z-10 mt-2 w-48 bg-white rounded-md shadow-lg">
              <div
                className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer ${
                  serviceOption === 'stinsurance' ? 'bg-gray-100' : ''
                }`}
                onClick={() => handleInsuranceOptionClick('stinsurance')}
              >
                Speech Therapy
              </div>
              <div
                className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer ${
                    serviceOption === 'otinsurance' ? 'bg-gray-100' : ''
                }`}
                onClick={() => handleInsuranceOptionClick('otinsurance')}
              >
                Occupational Therapy
              </div>
              <div
                className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer ${
                    serviceOption === 'pginsurance' ? 'bg-gray-100' : ''
                }`}
                onClick={() => handleInsuranceOptionClick('pginsurance')}
              >
                Peer Group
              </div>
            </div>
          )}
        </div>
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'officenotes'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('officenotes')}
        >
          Office Notes
        </button>
      </div>
      <div className="p-6">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default ProfileTable;