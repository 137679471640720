import React, { useState, useEffect, useRef } from 'react';
import { Trash2, Edit, Plus, Eye, Send, FileText, Check, X } from 'lucide-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import Header from './Header';

// Configuration
const API_KEY = 'f2517774c6321bf587f8e2a96fd4eb7c1048791c1331dc238447eed1cd75d7eb';
const API_BASE_URL = 'https://api.hellosign.com/v3';

const FormManagement = () => {
  // State Management
  const [forms, setForms] = useState([
    { 
      id: 'form1', 
      title: 'Patient Registration', 
      description: 'Comprehensive patient intake form',
      templateUrl: '/templates/patient-registration.pdf'
    },
    { 
      id: 'form2', 
      title: 'Consent Form', 
      description: 'Medical procedure consent document',
      templateUrl: '/templates/consent-form.pdf'
    }
  ]);
  const [newForm, setNewForm] = useState({ title: '', description: '' });
  const [signatureRequests, setSignatureRequests] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const fileInputRef = useRef(null);

  // Fetch existing signature requests
  useEffect(() => {
    fetchSignatureRequests();
  }, []);

  const fetchSignatureRequests = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/signature_request/list`, {
        auth: {
          username: API_KEY,
          password: ''
        }
      });
      
      const formattedRequests = response.data.signature_requests.map(request => ({
        id: request.signature_request_id,
        formName: request.title,
        date: new Date(request.created_at * 1000).toLocaleDateString(),
        status: request.is_complete ? 'Completed' : 'Pending',
        signees: request.signatures.map(sig => sig.signer_email_address)
      }));

      setSignatureRequests(formattedRequests);
    } catch (error) {
      console.error('Error fetching signature requests', error);
    }
  };

  // Form Management Functions
  const handleAddForm = () => {
    if (newForm.title && newForm.description) {
      const newFormEntry = {
        id: `form-${Date.now()}`,
        ...newForm
      };
      setForms([...forms, newFormEntry]);
      setNewForm({ title: '', description: '' });
      setModalMode(null);
    }
  };

  const handleDeleteForm = (formId) => {
    setForms(forms.filter(form => form.id !== formId));
  };

  const handleSendSignatureRequest = (form) => {
    setSelectedForm(form);
    setModalMode('send-request');
  };

  const submitSignatureRequest = async (email) => {
    if (!selectedForm || !email) return;

    try {
      const data = {
        test_mode: 1,
        title: selectedForm.title,
        subject: `Please sign: ${selectedForm.title}`,
        signers: [{ email_address: email }],
        files: [{ 
          name: `${selectedForm.title}.pdf`,
          file_url: selectedForm.templateUrl 
        }]
      };

      await axios.post(`${API_BASE_URL}/signature_request/send`, data, {
        auth: {
          username: API_KEY,
          password: ''
        }
      });

      fetchSignatureRequests();
      setModalMode(null);
    } catch (error) {
      console.error('Signature request error', error);
    }
  };

  // Modal Components
  const AddFormModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg w-96">
        <h2 className="text-2xl font-bold mb-4">Create New Form</h2>
        <input 
          type="text"
          placeholder="Form Title"
          value={newForm.title}
          onChange={(e) => setNewForm(prev => ({ ...prev, title: e.target.value }))}
          className="w-full mb-4 p-2 border rounded"
        />
        <textarea 
          placeholder="Form Description"
          value={newForm.description}
          onChange={(e) => setNewForm(prev => ({ ...prev, description: e.target.value }))}
          className="w-full mb-4 p-2 border rounded h-24"
        />
        <div className="flex justify-between">
          <button 
            onClick={handleAddForm}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            Create Form
          </button>
          <button 
            onClick={() => setModalMode(null)}
            className="bg-gray-200 text-gray-800 px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  const SendSignatureRequestModal = () => {
    const [email, setEmail] = useState('');

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-8 rounded-lg w-96">
          <h2 className="text-2xl font-bold mb-4">Send Signature Request</h2>
          <p className="mb-4 text-gray-600">Sending request for: {selectedForm.title}</p>
          <input 
            type="email"
            placeholder="Recipient's Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full mb-4 p-2 border rounded"
          />
          <div className="flex justify-between">
            <button 
              onClick={() => submitSignatureRequest(email)}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Send Request
            </button>
            <button 
              onClick={() => setModalMode(null)}
              className="bg-gray-200 text-gray-800 px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Header />
      <div className="min-h-screen bg-gray-100 p-8 mt-12">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-3 gap-8">
            {/* Form Templates Section */}
            <div className="col-span-1 bg-white shadow-lg rounded-lg p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold">Form Templates</h2>
                <button 
                  onClick={() => setModalMode('add-form')}
                  className="bg-green-500 text-white p-2 rounded-full hover:bg-green-600"
                >
                  <Plus />
                </button>
              </div>
              {forms.map(form => (
                <div 
                  key={form.id} 
                  className="border-b py-4 flex justify-between items-center hover:bg-gray-50 transition"
                >
                  <div>
                    <h3 className="font-semibold">{form.title}</h3>
                    <p className="text-gray-500 text-sm">{form.description}</p>
                  </div>
                  <div className="flex space-x-2">
                    <button 
                      onClick={() => handleSendSignatureRequest(form)}
                      className="text-blue-500 hover:bg-blue-100 p-2 rounded"
                    >
                      <Send size={16} />
                    </button>
                    <button 
                      onClick={() => handleDeleteForm(form.id)}
                      className="text-red-500 hover:bg-red-100 p-2 rounded"
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {/* Signature Requests Section */}
            <div className="col-span-2 bg-white shadow-lg rounded-lg p-6">
              <h2 className="text-2xl font-bold mb-6">Signature Requests</h2>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-100 text-gray-600">
                      <th className="p-3 text-left">Form Name</th>
                      <th className="p-3 text-left">Date</th>
                      <th className="p-3 text-left">Status</th>
                      <th className="p-3 text-left">Signees</th>
                    </tr>
                  </thead>
                  <tbody>
                    {signatureRequests.map(request => (
                      <tr key={request.id} className="border-b hover:bg-gray-50">
                        <td className="p-3">{request.formName}</td>
                        <td className="p-3">{request.date}</td>
                        <td className="p-3">
                          <span 
                            className={`px-2 py-1 rounded text-xs ${
                              request.status === 'Completed' 
                                ? 'bg-green-100 text-green-800' 
                                : 'bg-yellow-100 text-yellow-800'
                            }`}
                          >
                            {request.status}
                          </span>
                        </td>
                        <td className="p-3">{request.signees.join(', ')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}
        {modalMode === 'add-form' && <AddFormModal />}
        {modalMode === 'send-request' && <SendSignatureRequestModal />}
      </div>
      </div>
  );
};

export default FormManagement;